
export default function initInputRangeCustom() {
    const $inputsCustom = document.querySelectorAll<HTMLInputElement>('.input-range-custom');
    $inputsCustom.forEach($input => {
        new InputRangeCustom($input);
    });
}

/**
 * @param {HTMLInputElement} $el           HTML Element Input Custom Wrapper
 */
class InputRangeCustom {
    $el: HTMLInputElement | null | undefined;
    
    constructor($el: HTMLInputElement | null) {
        this.$el = $el;
        
        this.init();
    }

    init() {
        this.$el?.addEventListener('input', () => {
            if (this.$el && this.$el.value && this.$el.min && this.$el.max) {
                const value = (parseFloat(this.$el.value) - parseFloat(this.$el.min)) / (parseFloat(this.$el.max) - parseFloat(this.$el.min)) * 100;
                this.$el.style.backgroundImage = `linear-gradient(to right, #D6056A 0%, #D6056A ${value}%, rgba(240, 242, 245, 0.3) ${value}%, rgba(240, 242, 245, 0.3) 100%)`;
            }
        });
    }
}
