import initModals from './components/modal';
//import initReadMore from './modules/read-more-block';
import initAppearAnim from './utils/appear-anim';
import initNav from './components/nav';
import initDropdownGrid from './utils/dropdown-grid';
import initDropdown from './utils/dropdown';
//import initInputCustom from './components/input-custom';
//import { initYoutubeIframe } from './components/youtube-iframe';
import initMaillagePostsSimilaires from './template-parts/maillage-posts-similaires';
import initSingleContentSummary from './template-parts/single-content-summary';
import initTypeContent from './blocks/type-content';
import initAccordeon from './blocks/accordeon';
//import initGridPostsSwiper from "./components/grid-posts";
import initCarouselActus from './blocks/carousel-actus';
import initCarouselVignettes from './blocks/carousel-vignettes';
import initCarouselImagesPleineLargeur from './blocks/carousel-images-pleine-largeur';
import initTabsVertical from './blocks/tabs-vertical';
import initVideo from './blocks/video';
import initFormLivreBlanc from './utils/form-livre-blanc';
import initInputRangeCustom from './components/input-range-custom';
import initHeaderTarifs from './blocks/header-tarifs';
import initCardsTarif from './components/card-tarifs';
// import initCheckout from './template-parts/checkout';
// import initCart from "./template-parts/cart";
// import Sticksy from 'sticksy';
// import initLoginForm from './template-parts/login-form';

// Fix de la valeur de "100vh" pour les appareils iOS + Fix de la valeur de "100vw"
function appHeight() {
    const vh = (window.innerHeight * 0.01) * 100;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    const vw = document.body.clientWidth;
    document.documentElement.style.setProperty('--vw', `${vw}px`);
}
window.addEventListener('load', appHeight);
window.addEventListener('resize', appHeight);


// Utils
initAppearAnim();
initDropdownGrid();
initDropdown();
initFormLivreBlanc();

// Modules
//initReadMore();
initNav();

// Blocks
initTabsVertical();
initTypeContent();
initAccordeon();
initCarouselActus();
initCarouselVignettes();
initCarouselImagesPleineLargeur();
initVideo();
initHeaderTarifs();

//TEMPLATE PARTS
initMaillagePostsSimilaires();
initSingleContentSummary();

// Components (En dernier sinon les bouton modals dans un swiper ne s'initialise pas)
//initInputCustom();
initInputRangeCustom();
initModals();
initCardsTarif();
//initYoutubeIframe();
//initGridPostsSwiper();

// initLoginForm()
//initCheckout();
//initCart();
// new Sticksy('.js-sticky-widget', { topSpacing: 93, listen: true })
