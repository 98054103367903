export default function initCardsTarif() {
    const cardsTarif = document.querySelectorAll<HTMLElement>('.js-card-tarif');
    cardsTarif.forEach($container => {
        new CardTarif($container);
    });
}

/**
 * @param {HTMLElement} $el           HTML Element Block nos offres
 */
class CardTarif {
    $el: HTMLElement | null | undefined;
    $btnShowInfosSup: HTMLElement | null | undefined;
    $btnHideInfosSup: HTMLElement | null | undefined;
    $infosSup: HTMLElement | null | undefined;

    constructor($el: HTMLElement | null) {
        this.$el = $el;
        this.$btnShowInfosSup = this.$el?.querySelector('.js-show-infos-sup');
        this.$btnHideInfosSup = this.$el?.querySelector('.js-hide-infos-sup');
        this.$infosSup = this.$el?.querySelector('.js-card-infos-sup');

        this.init();
    }

    init() {
        if (this.$el?.classList.contains('card-tarif-range')) {
            this.initCardTarifRange();
        }
        
        if (this.$btnShowInfosSup && this.$btnHideInfosSup && this.$infosSup) {
            this.initInfosSupDropdown();
        }
    }

    initCardTarifRange() {
        const nbSignaturesContainer = this.$el?.querySelector<HTMLElement>('.js-nb-signatures');
        const priceValueContainer = this.$el?.querySelector<HTMLElement>('.js-price-value');
        let priceValueInit = parseFloat(priceValueContainer?.dataset.priceValue || '0');
        const inputRange = this.$el?.querySelector<HTMLInputElement>('.js-nb-signatures-range');
        const inputHiddenSignature = this.$el?.querySelector<HTMLInputElement>('.js-card-input-signature');
        const inputHiddenPrice = this.$el?.querySelector<HTMLInputElement>('.js-card-input-price');
        const rangeDecreasingData = inputRange?.dataset.rangeDecreasing;
    
        inputRange?.addEventListener('input', () => {
            let inputValue = Number(inputRange.value);
    
            // Si la valeur est 0, la changer à 1
            if (inputValue === 0) {
                inputValue = 1;
                inputRange.value = '1'; // Mettre à jour la valeur affichée dans l'input
            }
    
            // Calculer le nouveau prix et le nombre de signatures
            let newValue;
            if (rangeDecreasingData && rangeDecreasingData !== "false") {
                // Calculer le prix dégressif si des données sont fournies
                priceValueInit = this.calculateDecreasingPriceValue(JSON.parse(rangeDecreasingData), inputValue);
                newValue = Math.ceil(parseFloat((inputValue * priceValueInit).toFixed(2)));
            } else {
                // Sinon, utiliser le prix initial
                newValue = Math.ceil(inputValue * priceValueInit);
            }
    
            // Mettre à jour les éléments affichés
            if (priceValueContainer) {
                priceValueContainer.innerHTML = newValue.toString();
            }
    
            if (nbSignaturesContainer) {
                nbSignaturesContainer.innerHTML = inputValue.toString();
            }
    
            if (inputHiddenPrice) {
                inputHiddenPrice.value = newValue.toString();
            }
    
            if (inputHiddenSignature) {
                inputHiddenSignature.value = inputValue.toString();
            }
        });
    }

    // Calculer le prix degressing
    calculateDecreasingPriceValue(decreasingData: { range_max: string; price: string }[], inputValue: number): number {
        let priceValue = 0;

        for (let i = decreasingData.length - 1; i >= 0; i--) {
            const rangeMax = parseFloat(decreasingData[i].range_max);
            const price = parseFloat(decreasingData[i].price);

            if (inputValue < rangeMax) {
                priceValue = price;
            }
        }

        return priceValue;
    }

    // CARD TARIF DROPDOWN INFOS SUPP.
    initInfosSupDropdown() {
        this.$btnShowInfosSup?.addEventListener('click', () => {
            this.$infosSup?.classList.add('visible');
            this.$infosSup?.setAttribute('aria-hidden', 'false');
            this.$btnShowInfosSup?.classList.add('hidden');
        })

        this.$btnHideInfosSup?.addEventListener('click', () => {
            this.$infosSup?.classList.remove('visible');
            this.$btnShowInfosSup?.classList.remove('hidden');
            this.$infosSup?.setAttribute('aria-hidden', 'true');
        })
    }
}
