export default function initHeaderTarifs() {
    const $blocksHeaderTarifs = document.querySelectorAll<HTMLElement>('.header-tarifs');
    $blocksHeaderTarifs.forEach($container => {
        new BlockheaderTarifs($container);
    });
}

class BlockheaderTarifs {
    $el: HTMLElement | null | undefined;
    $btnTab1: HTMLElement | null | undefined;
    $btnTab2: HTMLElement | null | undefined;
    $wrapperCards1: HTMLElement | null | undefined;
    $wrapperCards2: HTMLElement | null | undefined;
    $wrapperTable1: HTMLElement | null | undefined;
    $wrapperTable2: HTMLElement | null | undefined;

    constructor($el: HTMLElement | null) {
        this.$el = $el;
        this.$btnTab1 = this.$el.querySelector('.js-tab-button1');
        this.$btnTab2 = this.$el.querySelector('.js-tab-button2');
        this.$wrapperCards1 = this.$el.querySelector('.js-wrapper-card-tarif1');
        this.$wrapperCards2 = this.$el.querySelector('.js-wrapper-card-tarif2');
        this.$wrapperTable1 = document.querySelector('.js-wrapper-comparative-table1');
        this.$wrapperTable2 = document.querySelector('.js-wrapper-comparative-table2');

        if (this.$btnTab1 && this.$btnTab2)  {
            this.initTabs();
        }
    }

    initTabs() {
        this.$btnTab1.addEventListener('click', () => {
            this.$btnTab1.classList.add('active');
            this.$btnTab2.classList.remove('active');
            this.$wrapperCards1.classList.remove('hidden');
            this.$wrapperCards2.classList.add('hidden');
            if (this.$wrapperTable1) {
                this.$wrapperTable1.classList.remove('hidden');
            }
            if (this.$wrapperTable2) {
                this.$wrapperTable2.classList.add('hidden');
            }
        })

        this.$btnTab2.addEventListener('click', () => {
            this.$btnTab1.classList.remove('active');
            this.$btnTab2.classList.add('active');
            this.$wrapperCards1.classList.add('hidden');
            this.$wrapperCards2.classList.remove('hidden');
            if (this.$wrapperTable1) {
                this.$wrapperTable1.classList.add('hidden');
            }
            if (this.$wrapperTable2) {
                this.$wrapperTable2.classList.remove('hidden');
            }
        })
    }
}
